import React from "react";

import { Container } from "@mui/material";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import TextsmsIcon from "@mui/icons-material/Textsms";
import BoltIcon from "@mui/icons-material/Bolt";
import ChipTag from "../components/common/ChipTag";

function CreateInterviewHome({ setCreationFlow }) {
  return (
    <Container>
      <div style={{ width: "100%" }}>
        <h2
          className="section mx-auto"
          style={{ marginTop: "10%", textAlign: "center" }}
        >
          Choose how to set up the interview
        </h2>
        <div
          className="d-flex flex-row mx-auto"
          style={{ margin: "auto", width: "100%" }}
        >
          <div
            className="card w-33 clickable"
            onClick={() => setCreationFlow("jd")}
            style={{ marginRight: "24px", padding: "24px", width: "100%" }}
          >
            <DocumentScannerIcon
              className="section color-gray"
              fontSize="large"
            />
            <h3 className="section-heading">Upload from job description</h3>
            <p className="section color-gray">
              Import a job description for a candidate and generate questions
              based on the role.
            </p>
            <ChipTag
              className="m-auto"
              variant="default"
              text="Recommended"
              style={{ marginLeft: "-2px", marginTop: "-8px" }}
            />
          </div>

          {/* <div className='card w-33 clickable' style={{ marginRight: '24px', padding: '24px' }}>
            <BoltIcon className='section color-gray' fontSize='large' />
            <h3 className='section-heading'>Choose template</h3>
            <p className='section color-gray'>
              Choose from starter templates created by our wizards.
            </p>
          </div> */}

          <div
            className="card w-33 clickable"
            onClick={() => setCreationFlow("self")}
            style={{ padding: "24px", width: "100%" }}
          >
            <TextsmsIcon className="section color-gray" fontSize="large" />
            <h3 className="section-heading">Create your own</h3>
            <p className="section color-gray">
              Tailor your own questions to be asked in the interview.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default CreateInterviewHome;
