import React from 'react'
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import TooltipCustom from './TooltipCustom';

const FormSelect = styled(({ className, label, noLabel, noBorder, required, tooltip, tooltipTitle, ...props }) => (
  <>
    {!noLabel && <h5 className="input-label d-flex">
      {label}{required ? <span className="color-required"> *</span> : ''}
      {tooltip ? <>
        <TooltipCustom 
          title={tooltipTitle}
          style={{ color: "var(--color-gray)", marginLeft: '8px', marginTop: 'auto', marginBottom: 'auto', fontSize: '14px' }}>
        </TooltipCustom>
      </> : ''}
    </h5>}
    
    <Select
      {...props}
      variant="outlined"
      style={{ 
        padding: '12px 16px', 
        marginBottom: '16px', 
        border: noBorder ? 'none' : '1px solid lightgray',
        borderRadius: '6px',
        fontSize: '14px' 
      }}
      sx={{
        ['& .MuiSelect-select'] : {
          padding: 0,
        }
      }}
      />
  </>
))({});

export default FormSelect