import React from 'react'
import { TableRow, TableCell } from '@mui/material';

const TableSection = ({ label }) => {
  return (
      <TableRow sx={{
        lineHeight: '16px',
        fontSize: '12px',
        fontWeight: '600',
        backgroundColor: '#E4DDFC52',
        color: 'var(--color-progress)',
        padding: '4px 16px',
        width: '100%'}}>
      <TableCell colSpan={4} sx={{
          lineHeight: '16px',
          fontSize: '14px',
          fontWeight: '600',
          color: 'var(--color-progress)',
          padding: '12px 16px',
          width: '100%'}}>
        {label}
      </TableCell>    
    </TableRow>
  )
}

export default TableSection