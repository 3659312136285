import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { AppBar, Toolbar, Avatar } from "@mui/material";
import { MenuList, MenuItem } from "@mui/material";
import { Popper, Grow, Paper, ClickAwayListener } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavigateBefore from "@mui/icons-material/NavigateBefore";

import api from "../../api/api";

import InterviewerLogo from "../../interviewer-logo.svg";

import Switcher from "../team/Switcher";

export const Navbar = ({ position, sx, user, isAuthenticated, isLoading }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth0();

  const [currentTeam, setCurrentTeam] = useState({});
  const [teams, setTeams] = useState([]);
  const [teamsOpen, setTeamsOpen] = useState(false);

  const [accountOpen, setAccountOpen] = useState(false);
  const accountRef = useRef(null);

  const handleAccountToggle = () => {
    setAccountOpen((prevOpen) => !prevOpen);
  };

  const handleAccountClose = (event) => {
    if (accountRef.current && accountRef.current.contains(event.target)) {
      return;
    }
    setAccountOpen(false);
  };

  const toggleTeams = () => {
    console.log(teamsOpen)
    setTeamsOpen((prev) => !prev)
  }


  // auto re-direct if no team found in teams list
  useEffect(() => {
      if(user && !isLoading) {
        var userId = user.email
        const globalOverrideUserLocal = localStorage.getItem('globalOverrideUser')
        if(globalOverrideUserLocal) {
          userId = globalOverrideUserLocal
        }

        // ping DB for user-specific team if exists. 
        // backend logic: if user-specific team does not exist, check if there is a team associated with the user and return that
        // if nothing exists, take them to team creation onboarding flow
        let teamId = localStorage.getItem('teamId')
        if(teamId && Object.keys(currentTeam).length === 0) {
          api.getTeam({ userId, teamId }).then((res) => {
            console.log('got it', res.data)
            const team = res.data
            setCurrentTeam(team)

            api.getTeams({ userId }).then((response) => {
              const fetchedTeams = response.data
              setTeams(fetchedTeams);
            })            
          }).catch((err) => {
            console.log(err)
            console.log('doing it the old fashioned way')
            localStorage.removeItem('teamId')

            api.getTeams({ userId }).then((response) => {
              console.log('got teams', response.data)
              const fetchedTeams = response.data
              setTeams(fetchedTeams);
              if (fetchedTeams.length === 0) {
                navigate('/your-team')
              }
    
              let invited_team_id = null
              fetchedTeams.map((team) => {
                team.members.map((member) => {
                  if(member.user_id === userId && member.status === 'invited') {
                    invited_team_id = team._id.$oid
                    return
                  }
                })
                return
              })
    
              if(invited_team_id) {
                localStorage.setItem('teamId', invited_team_id)
                navigate('/your-team')
              }

              console.log('no invites')
    
              // no invites found. check if active membership on any
              let active_team_id = null
              fetchedTeams.map((team) => {
                team.members.map((member) => {
                  if(member.user_id === userId && member.status === 'active') {
                    active_team_id = team._id.$oid
                    setCurrentTeam(team);
                    localStorage.setItem('teamId', active_team_id)
                    console.log('active', active_team_id, localStorage.getItem('teamId'))
                    return
                  }
                })
              })

              if(active_team_id) {
                console.log('found active')
                window.location.href = '/'
              }
            })
            .catch((error) => {
              console.log(error)
              // no teams found with user
              if (error.response.status == 400) {
                navigate('/your-team')
              }
            })            
          })
        }
    }
  }, [user, isLoading, setTeams, setCurrentTeam, teams, currentTeam]);

  return (
    <AppBar
      position={position !== null ? position : "static"}
      sx={sx !== null ? sx : {}}
      style={{
        backgroundColor: "white",
        boxShadow: "none",
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      <Toolbar
        style={{
          margin: "auto",
          minHeight: "56px",
          maxHeight: "48px",
          width: "100%",
          padding: "8px",
        }}
      >
        <button
          className="btn-main gray clear d-flex"
          style={{ height: "fit-content" }}
          aria-label="menu"
          onClick={() => {if (location.pathname != '/your-team') navigate("/interviews")}
          }
        >
          <img className="m-auto" alt="Interviewer" src={InterviewerLogo} />
        </button>
        {user && isAuthenticated && (
          <div className="d-flex flex-row" style={{ marginLeft: "auto" }}>
            <button
              className="btn-main gray clear d-flex flex-row"
              style={{ height: "40px" }}
              ref={accountRef}
              onClick={handleAccountToggle}
            >
              <Avatar
                className="my-auto"
                sx={{
                  bgcolor: "var(--color-primary)",
                  color: "white",
                  width: 32,
                  height: 32,
                  fontSize: "18px",
                  marginTop: "-2px",
                  marginRight: "6px",
                }}
              >
                {Array.from(user.email)[0]}
              </Avatar>
              <h6
                className="color-main my-auto"
                style={{ marginRight: "4px", fontSize: "12px" }}
              >
                {user.email}
              </h6>
              <ExpandMoreIcon className="my-auto color-main" fontSize="20px" />
            </button>
          </div>
        )}
      </Toolbar>

      {/* Actions */}
      <Popper
        open={accountOpen}
        anchorEl={accountRef.current}
        role={undefined}
        placement="bottom-end"
        style={{ width: '250px' }}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right top",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleAccountClose}>
                <MenuList
                  autoFocusItem={accountOpen}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  sx={{
                    ["& .MuiMenuItem-root"]: {
                      fontSize: "14px",
                    },
                    ["& .MuiTypography-root"]: {
                      fontSize: "14px",
                    },
                  }}
                >
                  <Switcher team={currentTeam} teams={teams} popper={false} clicked={toggleTeams}></Switcher>
                  {teamsOpen ?  
                  <>
                    <h6 className="clickable color-gray" style={{ margin: '8px  16px', fontSize: '12px' }}
                      onClick={() => setTeamsOpen(false)}>
                      <NavigateBefore fontSize="14px" className="color-gray m-auto" style={{ marginRight: '8px' }} />
                      List of Teams
                    </h6>
                    {teams.map((team) => {
                      return (
                        <MenuItem key={team._id.$oid} value={team._id.$oid} onClick={() => {
                          if (currentTeam._id.$oid != team._id.$oid) {
                            console.log('new team id', team)
                            localStorage.setItem('teamId', team._id.$oid)
                            setCurrentTeam(team)
                            navigate('/interviews')
                          }
                        }}>
                          {team.name}
                        </MenuItem>
                      )
                    })}
                  </>
                  :
                  <>
                    <MenuItem
                      onClick={() => {
                        navigate("/settings");
                      }}
                    >
                      <ListItemIcon>
                        <SettingsIcon fontSize="14px" />
                      </ListItemIcon>
                      <ListItemText>Settings</ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        localStorage.removeItem('teamId')
                        localStorage.removeItem('globalOverrideUser')
                        logout({ returnTo: "/" });
                      }}
                    >
                      <ListItemIcon>
                        <LogoutIcon className="color-danger" fontSize="14px" />
                      </ListItemIcon>
                      <ListItemText className="color-danger">Logout</ListItemText>
                    </MenuItem>
                  </>}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </AppBar>
  );
};
