import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  InterviewDetailList,
  SessionDetail,
  InterviewDetail,
  CreateInterview,
  InterviewsList,
  Login,
  Logout,
  Settings,
} from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import CreateInterviewHome from "./pages/CreateInterviewHome";
import CreateInterviewJD from "./pages/CreateInterviewJD";
import YourTeam from "./pages/YourTeam";
import BaseLayout from "./layouts/BaseLayout";
posthog.init("phc_WcTIhKTBtZfgo4URTcSni32bkl8oACPOtBZ5t6sYmW9", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "identified_only",
});

Sentry.init({
  dsn: "https://b1c31689814476854f723725e3636880@o4507392418775040.ingest.us.sentry.io/4507392429326336",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Override Material UI Theme Font
const customTheme = createTheme({
  typography: {
    fontFamily: "Figtree, sans-serif",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-lqmv068c84w1mc76.us.auth0.com"
      clientId="GU5s0IgyrsF8oIu0oRd4GnvAIayrB2C5"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <ThemeProvider theme={customTheme}>
        <Router>
          <BaseLayout>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/your-team"
                element={<YourTeam />}
              />
              <Route path="/interviews" element={<InterviewDetailList />} />
              <Route
                path="/create-interview"
                element={<CreateInterview />}
              />
              <Route path="/public/sessions/:session_id" element={<SessionDetail isPubliclyViewable={true} />} />
              <Route path="/interviews/:id" element={<InterviewDetail />} />
              <Route
                path="/interviews/:interview_id/sessions/:session_id"
                element={<SessionDetail />}
              />
              <Route path="/logout" element={<Logout />} />
              <Route path="/settings" element={<Settings />} />
            </Routes>
          </BaseLayout>
        </Router>
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
