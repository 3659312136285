import axios from "axios";

var env = String(process.env.REACT_APP_ENV);

var proxyEndpoint =
  env === "development"
    ? "http://localhost:5555"
    : env === "staging"
    ? "https://staging-proxy-6f8d86796ff2.herokuapp.com"
    : "https://lit-dusk-21105-d4d3c182eb10.herokuapp.com"; // env === "production"

export default {
  createInterview: ({ userId, teamId, interviewConfig }) => {
    return axios.post(proxyEndpoint + "/interviews/create", {
      userId,
      teamId,
      interviewConfig,
    });
  },
  getPublicInterview: ({ sessionId }) => {
    return axios.post(proxyEndpoint + "/interviews/public", {
      sessionId
    });
  },
  createTeam: ({ userId, teamDetails }) => {
    return axios.post(proxyEndpoint + "/team/create", {
      userId,
      teamDetails
    });
  },
  getTeam: ({ userId, teamId }) => {
    return axios.post(proxyEndpoint + "/team", {
      userId,
      teamId
    });
  },
  getTeams: ({ userId }) => {
    return axios.post(proxyEndpoint + "/teams", {
      userId
    });
  },
  acceptJoinTeam: ({ userId, teamId }) => {
    return axios.post(proxyEndpoint + "/team/accept-join", {
      userId,
      teamId
    });
  },
  deleteTeamMember: ({ memberId, teamId, userId }) => {
    return axios.post(proxyEndpoint + "/team/delete-member", {
      memberId,
      teamId,
      userId
    });
  },
  editTeamMember: ({ memberId, teamId, userId }) => {
    return axios.post(proxyEndpoint + "/team/edit-member", {
      memberId,
      teamId,
      userId
    });
  },
  inviteTeamMember: ({ memberId, teamId, userId, role }) => {
    return axios.post(proxyEndpoint + "/team/invite-member", {
      memberId,
      teamId,
      userId,
      role
    });
  },
  getInterview: (id, userId, teamId) => {
    return axios.post(proxyEndpoint + "/interview", { id, userId, teamId });
  },
  getInterviews: (userId, teamId) => {
    return axios.post(proxyEndpoint + '/interviews', { userId, teamId }) // TODO: add session token auth
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(err => {
      console.log(err);
    });
  },
  updateInterview: (interview_id, interview) => {
    return axios.post(`${proxyEndpoint}/interviews/${interview_id}`, {
      interview,
    });
  },
  generateInterviewQuestions: ({ context, interview_config_id, context_type }) => {
    return axios.post(proxyEndpoint + "/generate_interview_questions", {
      context,
      interview_config_id,
      context_type,
    });
  },
  generateScoringCriteria: ({ context, signal, scale, title, job_title }) => {
    return axios.post(proxyEndpoint + "/generate_scoring_criteria", {
      context,
      signal,
      scale,
      title,
      job_title
    });
  },
  generateConversationPreview: ({ topic, follow_ups, interview_config_id, job_title, title }) => {
    return axios.post(proxyEndpoint + "/generate_conversation_preview", {
      topic,
      follow_ups,
      interview_config_id,
      job_title,
      title
    });
  },
  createBulkInterviewSessions: ({ interviewConfigId, userId, teamId, candidates, inviteType }) => {
    return axios.post(proxyEndpoint + "/sessions-bulk", {
      interviewConfigId,
      userId,
      teamId,
      candidates,
      inviteType
    });
  },
};
