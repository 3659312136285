import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Container, Grid, CircularProgress } from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import DragHandleIcon from "@mui/icons-material/DragHandle";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";
import ClearIcon from "@mui/icons-material/Clear";

import EditIconNewSvg from "../EditIconNew.svg";
import DeleteIconNewSvg from "../DeleteIconNew.svg";

import { CSS } from "@dnd-kit/utilities";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

// needed for row & cell level scope DnD setup
import { useSortable } from "@dnd-kit/sortable";
import { useAuth0 } from "@auth0/auth0-react";
import pdfToText from "react-pdftotext";

import FormField from "../components/common/FormField";
import TooltipCustom from "../components/common/TooltipCustom";

import api from "../api/api";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const RowDragHandleCell = ({ rowId }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });

  console.log(rowId);

  console.log(attributes, listeners);

  return (
    <IconButton {...attributes} {...listeners}>
      <DragHandleIcon />
    </IconButton>
  );
};

function CreateInterviewJD({
  setCreationFlow,
  setActiveStep,
  setAddQuestions,
  setShowAddQuestionInput,
  setRoleBackground,
  roleBackground,
  showInterviewSetup,
  headerText,
  label,
  sx,
  fullWidth,
  btnText
}) {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const [interview, setInterview] = useState({});
  const [activeSubStep, setActiveSubStep] = useState(0);
  const [jobDescriptionText, setJobDescriptionText] = useState("");
  const [file, setFile] = useState("");
  const [fileText, setFileText] = useState("");

  const [submitting, setSubmitting] = useState(false);

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Questions page
  const [currentQuestion, setCurrentQuestion] = useState({
    text: "",
    numFollowUps: 0,
    editIndex: -1,
  });
  const [
    showQuestionDeletionConfirmation,
    setShowQuestionDeletionConfirmation,
  ] = useState(false);
  const [questionDeletionIndex, setQuestionDeletionIndex] = useState(-1);

  useEffect(() => {
    api
      .getInterview(interview.id, user.email)
      .then((response) => {
        setInterview(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleFileUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFile(file);
      processFile(file);
    }
  };

  const processFile = (file) => {
    if (file.type === "application/pdf") {
      pdfToText(file)
        .then((text) => {
          setFileText(text);
        })
        .catch((error) => console.error("Failed to extract text from pdf"));
    } else {
      // text file
      var reader = new FileReader();

      if (file.type.match(/text.*/)) {
        reader.onload = function (event) {
          setFileText(event.target.result);
        };
      } else {
        console.log("Failed to read a non-text file.");
      }
      reader.readAsText(file);
    }
  };

  const clearFile = () => {
    setFile("");
    setFileText("");
  };

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }

  const handleDragEnd = (event) => {
    console.log("handleDragEnd");
    const { active, over } = event;

    if (active.id !== over.id) {
      let updatedInterview = interview;

      const oldIndex = interview.questions.findIndex(
        (question) => question.id === active.id
      );
      const newIndex = interview.questions.findIndex(
        (question) => question.id === over.id
      );
      updatedInterview = {
        ...interview,
        questions: arrayMove(interview.questions, oldIndex, newIndex),
      };

      api
        .updateInterview(interview._id, updatedInterview)
        .then((response) => {
          setInterview(response.data);
          setCurrentQuestion({
            text: "",
            numFollowUps: 1,
            editIndex: -1,
          });
          setOpenSnackbar(true);
          setSnackbarMsg("Questions have been updated");
        })
        .catch((error) => {
          console.error("Failed to update interview questions:", error);
        });
    }
  };

  const previousSubStep = () => {
    setActiveStep(activeSubStep - 1);
    if (activeSubStep == 0) navigate("/create-interview");
  };
  const nextSubStep = () => {
    setActiveStep(activeSubStep + 1);
  };

  const handleSubmitJD = () => {
    const payload = {
      context: jobDescriptionText.concat(fileText),
      context_type: "job_description",
    };
    setSubmitting(true);
    api
      .generateInterviewQuestions(payload)
      .then((response) => {
        setSubmitting(false);
        console.log("generated interview questions");
        console.log(response);

        setRoleBackground({
          ...roleBackground,
          interviewTitle: response.data.details.interview_title,
          jobTitle: response.data.details.job_title,
        });
        setAddQuestions({
          questions: response.data.questions.map((question) => {
            return { ...question, text: question.question, id: uuidv4() };
          }),
          currentQuestion: {
            text: "What has been the highlight of your role?",
            numFollowUps: 1,
          },
        });

        setActiveStep((prevStep) => prevStep + 1); // set question flow back to original self create (just with newly generate q's)
      })
      .catch((error) => {
        setSubmitting(false);
        console.error("Failed to generate interview questions:", error);
      });
  };

  const renderUploadStep = () => {
    return (
      <div
        className="mx-auto"
        style={{ paddingTop: "40px", paddingBottom: "80px" }}
      >
        <div className="d-flex flex-col m-auto" style={{ width: fullWidth ? "100%" : "60%" }}>
          <Box
            className="d-flex flex-col card section mx-auto"
            style={{ padding: "24px", width: "100%" }}
          >
            <h3 className="section" style={{ marginTop: 0 }}>
              {headerText ? headerText : 'Upload or write candidate job description'}
            </h3>

            <FormField
              label={label ? label : "Job Description"}
              placeholder="Description of roles and responsibilities include..."
              value={jobDescriptionText}
              multiline
              minRows={6}
              onChange={(e) => {
                setJobDescriptionText(e.target.value);
              }}
            />
            <TooltipCustom
              title={
                <>
                  <p className="m-auto">Supports .pdf, .txt files.</p>
                </>
              }
            >
              <Button
                className="btn-upload d-flex flex-col"
                sx={{
                  height: "fit-content",
                  alignItems: "flex-start",
                  padding: "12px",
                  backgroundColor: "var(--color-lightgray) !important",
                  boxShadow: "none",
                  border: "1px dashed lightgray !important",
                }}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
              >
                <p className="section-heading">
                  Upload job description or any past interview notes/information
                  on the role
                </p>
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFileUpload}
                  accept="application/pdf,.txt"
                />
                <div className="d-flex flex-row">
                  <div className="btn-main no-hover">Upload file</div>
                  {file && (
                    <>
                      <p style={{ fontSize: "12px", margin: "auto 12px" }}>
                        {file.name}
                      </p>

                      <ClearIcon
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          clearFile();
                        }}
                        className="color-danger my-auto"
                        fontSize="24px"
                      ></ClearIcon>
                    </>
                  )}
                </div>
              </Button>
            </TooltipCustom>
          </Box>
          
            <div className="d-flex flex-row">
            {showInterviewSetup &&
              <div className="d-flex flex-row" style={{ marginRight: "auto" }}>
                <button
                  disabled={submitting}
                  className="btn-main small d-flex gray outlined my-auto"
                  onClick={() => setCreationFlow('')}
                >
                  <NavigateBefore fontSize="14px" className="color-heading m-auto" />
                </button>
                  <h5 className={ submitting ? 'inactive' : '' }style={{ margin: "auto 8px", lineHeight: "24px" }}>Back to interview setup</h5>              
              </div>
            }

          <button
            className="btn-main d-flex"
            onClick={() => handleSubmitJD()}
            disabled={
              submitting || (jobDescriptionText == "" && fileText == "")
            }
            style={{
              width: "fit-content",
              marginLeft: "auto",
            }}
          >
            {submitting ? (
              <>
                {btnText ? btnText : 'Generating job details and interview questions...'}
                &nbsp;&nbsp;
                <CircularProgress
                  className="m-auto"
                  size={14}
                  style={{ color: "white", marginLeft: "8px" }}
                />
              </>
            ) : (
              "Submit"
            )}
          </button>
          </div>
        </div>
      </div>
    );
  };

  const InterviewDetailDraggableRow = ({
    idx,
    question,
    questions,
    setCurrentQuestion,
    setShowAddQuestionInput,
    setQuestionDeletionIndex,
    setShowQuestionDeletionConfirmation,
  }) => {
    const { transform, transition, setNodeRef, isDragging } = useSortable({
      id: question.id,
    });

    const style = {
      transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
      transition: transition,
      opacity: isDragging ? 0.8 : 1,
      zIndex: isDragging ? 1 : 0,
      position: "relative",
    };

    return (
      <TableRow
        key={question.id}
        ref={setNodeRef}
        sx={{
          transform: CSS.Transform.toString(transform),
          transition: transition,
          opacity: isDragging ? 0.8 : 1,
          zIndex: isDragging ? 1 : 0,
          position: "relative",
          border: "none",
          borderBottom: "1px solid lightgray",
          padding: "0px",
          margin: "0px",
        }}
      >
        <TableCell>
          <RowDragHandleCell rowId={question.id} />
        </TableCell>
        <TableCell
          sx={{
            display: "flex",
            flexDirection: "row",
            border: "none",
            marginTop: "10px",
            fontWeight: 600,
            fontSize: "14px",
          }}
        >
          {question.question}
        </TableCell>
        <TableCell sx={{ padding: "0px", margin: "0px", paddingLeft: "17px" }}>
          {question.follow_ups}
        </TableCell>
        <TableCell sx={{ padding: "0px", margin: "0px", marginLeft: "40px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            {/* Edit */}
            <IconButton
              onClick={() => {
                setCurrentQuestion({
                  text: question.question,
                  numFollowUps: question.follow_ups,
                  editIndex: idx,
                });
                setShowAddQuestionInput(true);
              }}
              className="drop-shadow-btn"
              style={{
                marginLeft: "15px",
                color: "black",
                fontSize: "14px",
                fontWeight: 500,
                marginRight: "8px",
                borderRadius: "9px",
                border: "0.5px solid lightgray",
                padding: "6px 9px 6px 8px",
              }}
            >
              <img src={EditIconNewSvg} /> Edit
            </IconButton>
            {/* Delete */}
            <IconButton
              disabled={questions.length === 1}
              onClick={() => {
                setQuestionDeletionIndex(idx);
                setShowQuestionDeletionConfirmation(true);
              }}
              className="drop-shadow-btn"
              style={{
                background: "#FFF8F8",
                color: "#F33D3D",
                fontSize: "14px",
                fontWeight: 500,
                marginRight: "4px",
                borderRadius: "9px",
                border: "0.5px solid lightgray",
                padding: "6px 9px 6px 8px",
              }}
            >
              <img src={DeleteIconNewSvg} /> Delete
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  /** this section is obsolete due to relying on question table
   * framework of the self-created interview questions, just fed with
   * imported questions.
   */
  const renderQuestions = () => {
    return (
      <div>
        {/* Main Content */}
        <div className="section d-flex flex-col">
          <h3 className="section-heading" style={{ marginTop: 0 }}>
            Voice assessment topics
          </h3>
          <p className="color-gray" style={{ margin: 0 }}>
            Asked in order of topics
          </p>
        </div>
        <div className="card">
          {/* TODO: style rest of interview tabs to match consistent action buttons, modals */}
          <div
            className="d-flex flex-row"
            style={{
              padding: "12px 16px",
              borderBottom: "1px solid lightgray",
              marginBottom: 0,
            }}
          >
            <div
              style={{
                backgroundColor: "var(--color-lightgray)",
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "600",
                padding: "6px 8px",
              }}
            >
              All Topics ({interview.questions.length})
            </div>
            <button
              className="btn-main"
              style={{
                marginLeft: "auto",
                marginBottom: 0,
                padding: "6px 9.24px 6px 8px",
                fontWeight: 600,
                fontSize: "14px",
              }}
              onClick={() => {
                setShowAddQuestionInput(true);
              }}
            >
              + Add topics
            </button>
          </div>
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
            sensors={sensors}
          >
            <Grid style={{ marginTop: 0 }}>
              <TableContainer style={{ marginTop: 0 }}>
                <Table style={{ marginTop: 0 }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          lineHeight: "20px",
                          backgroundColor: "#FAFAFA",
                          color: "var(--color-heading)",
                          padding: "12px 16px",
                        },
                      }}
                    >
                      <TableCell></TableCell>
                      <TableCell style={{ width: "60%" }}>Topic</TableCell>
                      <TableCell style={{ minWidth: "72px" }}>Depth</TableCell>
                      <TableCell>Quick Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <SortableContext
                      items={interview.questions.map((question, idx) => {
                        return question.id;
                      })}
                      strategy={verticalListSortingStrategy}
                    >
                      {interview.questions.map((question, idx) => (
                        <InterviewDetailDraggableRow
                          idx={idx}
                          question={question}
                          questions={interview.questions}
                          setCurrentQuestion={setCurrentQuestion}
                          setQuestionDeletionIndex={setQuestionDeletionIndex}
                          setShowQuestionDeletionConfirmation={
                            setShowQuestionDeletionConfirmation
                          }
                          setShowAddQuestionInput={setShowAddQuestionInput}
                        />
                      ))}
                    </SortableContext>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </DndContext>
        </div>
      </div>
    );
  };

  const renderActiveSubStepComponent = () => {
    switch (activeSubStep) {
      case 0:
        return renderUploadStep();
      case 1:
        return renderQuestions();
      default:
        return (
          <>
            <CircularProgress />
          </>
        );
    }
  };

  return (
    <Container>
      {renderActiveSubStepComponent()}
    </Container>
  );
}

export default CreateInterviewJD;
