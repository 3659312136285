import React, { useState, useRef } from "react";
import { Avatar, Popper, Grow, Paper, MenuList, MenuItem } from "@mui/material";
import { ClickAwayListener } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Switcher({ team, teams, popper, clicked }) {
  const [teamOpen, setTeamOpen] = useState(false);
  const teamRef = useRef(null);

  const handleTeamToggle = () => {
    console.log('toggling')
    clicked();
    setTeamOpen((prevOpen) => !prevOpen);
  };

  const handleTeamClose = (event) => {
    if (teamRef.current && teamRef.current.contains(event.target)) {
      return;
    }
    setTeamOpen(false);
  };

  let teamInitial = 'Initial'
  if(Object.keys(team).length > 0) {
    teamInitial = team.name
  }

  return (
    <>
      <div className="d-flex flex-row" style={{ marginLeft: "auto", width: '100%' }}>
        <button
          className="btn-main gray clear d-flex flex-row"
          style={{ height: "56px", borderBottom: '1px solid lightgray !important', borderRadius: '0px !important;', flex: 1 }}
          ref={teamRef}
          onClick={handleTeamToggle}
        >
            <Avatar
            className="my-auto"
            sx={{
              bgcolor: "var(--color-primary)",
              color: "white",
              width: 32,
              height: 32,
              borderRadius: "6px",
              fontSize: "18px",
              marginRight: "12px",
            }}
          >
            {teamInitial[0]}
          </Avatar>          
          <div className="d-flex flex-col my-auto" style={{ textAlign: "start" }}>
            <h6 className="color-gray my-auto" style={{ lineHeight: '16px' }}>Team name</h6>
            <h6
              className="color-main my-auto"
              style={{ marginRight: "4px", fontSize: "12px", lineHeight: '16px' }}
            >
              {team.name}
            </h6>
          </div>
          {/* <ExpandMoreIcon className="my-auto color-main" fontSize="20px" style={{ marginLeft: 'auto' }} /> */}
          <span className="btn-main small d-flex gray outlined my-auto" style={{ marginLeft: 'auto' }}>
            <ExpandMoreIcon fontSize="14px" className="color-heading m-auto" />
          </span>
        </button>
      </div>

      {/* Teams */}
      {popper && <Popper
        open={teamOpen}
        anchorEl={teamRef.current}
        role={undefined}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right top",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleTeamClose}>
                <MenuList
                  autoFocusItem={teamOpen}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  sx={{
                    ["& .MuiMenuItem-root"]: {
                      fontSize: "14px",
                    },
                    ["& .MuiTypography-root"]: {
                      fontSize: "14px",
                    },
                  }}
                >
                  {teams.map((team) => {
                    return (
                      <MenuItem key={team._id.$oid} value={team._id.$oid}>{team.name}</MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>}
    </>
  );
}

export default Switcher;
